import React, {useState} from 'react';
import PropTypes from 'prop-types'
import {ParallaxProvider} from 'react-scroll-parallax';
import Block from '../../adapters/helpers/Block'
import { LayoutConstants } from '../../adapters/helpers/Constants'
import LayoutContext from './LayoutContext';

function Layout(props) {
    const block = new Block(props);
    const extraAttributes = props.extraAttributes || {};
    const [customEvent, setCustomEvent] = useState({
        event: {}
    });

    const hasSpotlightContestInChildren = () => {
        const doc = block.getDocument();
        let hasContestInChildren = false;
    
        if (doc.fields && doc.fields.blocks) {
            doc.fields.blocks.forEach(block => {
                if (block.fields.code === LayoutConstants.zoneContestCode || block.fields.code === LayoutConstants.zoneThankYouCode) {
                    hasContestInChildren = true;
                }
            });
        }
        
        return hasContestInChildren;
    };
    
    const hasContestInChildren = hasSpotlightContestInChildren();
    const [isContestVisible, setIsContestVisible] = useState(hasContestInChildren);
    
    const onClickCallback = (event) => {
        if (event) {
            setCustomEvent({
                event: event.target
            });
        }
    };
    
    const layoutContextProvider = {
        onContestScrollDown: () => {
            setIsContestVisible(false);
        },
        onSliderScrollUpTop: () => {
            setIsContestVisible(true);
        },
        hasContestInChildren: hasContestInChildren,
        isContestVisible: isContestVisible,
    };  
    let layoutClass = 'layout '
    if(block.getFieldValue(LayoutConstants.classNames)){
        layoutClass = layoutClass + block.getFieldValue(LayoutConstants.classNames)
    }

    return (
        hasContestInChildren ?
        <div className={layoutClass}>
            <LayoutContext.Provider value={layoutContextProvider}>
                <ParallaxProvider>
                    {block.renderChildBlocks({extraAttributes}, onClickCallback, customEvent)}
                </ParallaxProvider>
            </LayoutContext.Provider>
        </div>
            :
        <div className={layoutClass}>
            <ParallaxProvider>
                {block.renderChildBlocks({extraAttributes}, onClickCallback, customEvent)}
            </ParallaxProvider>
        </div>
    );
}

export default Layout;

Layout.propTypes = {
    extraAttributes: PropTypes.object,
};
